import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

const Button  = styled.button`
  width: 100%;
  padding: 1rem;
  margin: 1rem auto 0;
  background-color: #ffdf40;
  border-radius: 3rem;
  box-shadow: 0 0 3px #310505;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(45deg, #ff9800, transparent);
    box-shadow: 0 0 5px #310505;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 3rem auto;
  box-shadow: 0 0 5px #e2dede;
  background-color: #fff;
  border-radius: 0.5rem;
  @media (max-width: 20cm) {
    width: 90%;
  }
`;

const FormContainer  = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 3rem auto;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem 2rem;
  border-radius: 2rem;
  box-shadow: 0 0 2px #999;
  text-align: center;
  margin-top: 0.5rem
`;

const Login = ({setIsLogedIn, isLogedIn}) => {
  setIsLogedIn(localStorage.getItem('token') ? true : false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    const value = event.target.value;
    setUsername(value);
  };
  
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    try {
      const response = await axios.post('/login', formData);
        
      localStorage.setItem('token', response.data.data.token);
      setIsLogedIn(true);
    } catch (error) {
      // console.error(error);
    }
  };

  if (isLogedIn) {
    console.log('a');
    return <Navigate to="/" />;
  } else {
    console.log('b');
    return (
      <Main>
        <FormContainer>
          <form onSubmit={handleSubmit}>
              <Input type="text" placeholder='نام کاربری' value={username} onChange={handleUsernameChange} />
              <Input type="password" placeholder='رمز عبور' value={password} onChange={handlePasswordChange} />
            <Button type='submit'>ورود به حساب کاربری</Button>
          </form>
        </FormContainer>
      </Main>
    );
  }
};

export default Login;
