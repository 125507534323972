import { styled } from 'styled-components';
import axios from "axios";
import { useState } from 'react';

const Main = styled.div`
    width: 50%;
    margin-bottom: 4rem;

    @media (min-width: 768px) {
        width: 33.33%;
    }

    @media (min-width: 1024px) {
        width: 25%;
    }
`;

const ImageDiv = styled.div`
    width: 80%;
    height: 0;
    padding-bottom: 80%;
    margin: auto;
    box-shadow: 0 0 1px #d0d0d0;
    border-radius: 25px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const LikeIcon = styled.img`
    width: 2rem;
    margin: -2.2rem 22% 0 0;
`;


const TitleDiv = styled.div`
    text-align: center;
    font-size: 0.8rem;
`;

const DetailsDiv = styled.div`
    text-align: center;
    font-size: 0.6rem;
    color: gray;
`;

const ContentDiv = styled.div`
    // display: flex;
    width: 80%;
    margin: auto;
    // background-color: gray;
`;

const TextDiv = styled.div`
    // background-color: red;
`;

const Test = styled.div`
    width: 22.2px;
    height: 20px;
    margin: -25px 12% 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const likeImageLink = (data) => {
    if (data.likedByMe === '1' && data.likedByMyFriend === '1') {
        return 'liked by both of us.png';
    } else if (data.likedByMe === '1') {
        return 'liked by me.png';
    } else if (data.likedByMyFriend === '1') {
        return 'liked by my friend.png';
    } else {
        return 'liked by none of us.png';
    }
}

const Collection = ({data, getSongs}) => {
    const [likeImage, setLikeImage] = useState(likeImageLink(data));
    const [likeCount, setLikeCount] = useState(data.likeCount)
    const [songCount, setSongCount] = useState(data.songCount);

    const handleLike = async (singerId) => {
        try {
            const formData = new FormData();
            formData.append('singerId', singerId);
            
            const response = await axios.post('/singer/like', formData);
            setLikeImage(likeImageLink(response.data.data));
            setLikeCount(response.data.data.likeCount);
            setSongCount(response.data.data.songCount);
        } catch (error) {
    
        }
    }

    var clickCountOnMain = 0;

    return (
        <Main
            onClick={(e) => {
                clickCountOnMain = e.detail;
                setTimeout(() => {
                    if (clickCountOnMain === 1) {
                        clickCountOnMain = 0;
                        getSongs(data.id);
                    } else if (clickCountOnMain === 2) {
                        clickCountOnMain = 0;
                        handleLike();
                    }
                }, 100);
            }}
        >
            {/* <a href={`/songs/singerId=${data.id}`}> */}
                <ImageDiv style={{backgroundImage: `url("https://media1.hamahangim.com/img/singer/${data.id}.jpg")`}} />
                {/* <LikeIcon src="./img/like/liked by both of us.png"/> */}
                <Test
                    onClick={(e) => {
                        e.stopPropagation();
                        handleLike(data.id);
                    }}
                    style={{backgroundImage: `url("./img/like/${likeImage}")`}}
                />
                <ContentDiv>
                    {/* <LikeIcon src="./img/like/liked by both of us.png"/> */}
                    <TitleDiv>{ data.name }</TitleDiv>
                    <DetailsDiv>{ likeCount } لایک | { songCount } آهنگ</DetailsDiv>
                </ContentDiv>
            {/* </a> */}
        </Main>
    );
}

export default Collection;