import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'yekan';
    src: url('/fonts/Yekan.eot');
    src: url('/fonts/Yekan.eot?#iefix') format('embedded-opentype'), url('/fonts/Yekan.woff') format('woff'), url('/fonts/Yekan.ttf') format('truetype'), url('/fonts/Yekan.svg#BYekan') format('svg');
    font-weight: normal;
    font-style: normal
  }

  *{
    box-sizing: border-box;
    direction: rtl;
    font-family: 'yekan';
    font-size: 1rem;
    background: none;
    border: none;
    outline: 0;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  body{
    background-color: #fafafb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }

  a{
    color: unset;
  }
  a:visited {
    color: unset;
  }
`;

export default GlobalStyles;
