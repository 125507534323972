import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "styled-components";

const SongDiv = styled.div`
    width: 96%;
    margin: 0.5rem auto;
    box-shadow: 0 1px 1px -1px gray;
    height: 58px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
`;

const ContentDiv = styled.div`
    width: 100%;
    display: flex;
    flex-grow: 1;
`;

const ImageDiv = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 7px;
    background-color: white;
    margin: 6px 9px;
    overflow: hidden;
`;

const TextDiv = styled.div`
    height: calc(100% - 8px);
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.4rem;
    color: #373737;
`;

const TitleDive = styled.div`
    flex-grow: 1;
    font-size: 0.8rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

const SingerDiv = styled.div`
    flex-grow: 1;
    font-size: 0.5rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

const LikeDiv = styled.div`
    height: calc(100% - 6px);
    margin: 11px 9px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #373737;
`;

const LikeImageDive = styled.div`
    width: 22.5px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const LikeCountDiv = styled.div`
    font-size: 0.5rem;
    text-align: center;
`;

const likeImageLink = (data) => {
    if (data.likedByMe === '1' && data.likedByMyFriend === '1') {
        return 'liked by both of us.png';
    } else if (data.likedByMe === '1') {
        return 'liked by me.png';
    } else if (data.likedByMyFriend === '1') {
        return 'liked by my friend.png';
    } else {
        return 'liked by none of us.png';
    }
}

const Song = ({song, changeSong}) => {
    const [likeImage, setLikeImage] = useState(likeImageLink(song));
    const [likeCount, setLikeCount] = useState(song.likeCount);

    const handleLike = async (songId) => {
        try {
            const formData = new FormData();
            formData.append('songId', songId);
            
            const response = await axios.post('/song/like', formData);
            setLikeImage(likeImageLink(response.data.data));
            setLikeCount(response.data.data.likeCount);
        } catch (error) {
    
        }
    }

    return (
        <SongDiv
            onClick={(e) => {
                changeSong(song.id);
            }}
        >
            <ContentDiv>
                <ImageDiv>
                    <img width="100%" src={`https://media1.hamahangim.com/img/song/${song.id}.jpg`} />
                </ImageDiv>
                <TextDiv>
                    <TitleDive>{song.songName}</TitleDive>
                    <SingerDiv>{song.singerName}</SingerDiv>
                </TextDiv>
                <LikeDiv
                    onClick={(e) => {
                        e.stopPropagation();
                        handleLike(song.id);
                    }}
                >
                    <LikeImageDive style={{backgroundImage: `url("./img/like/${likeImage}")`}} />
                    <LikeCountDiv>{likeCount}</LikeCountDiv>
                </LikeDiv>
            </ContentDiv>
        </SongDiv>
    );
}

export default Song;