import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';

const firebaseConfig = {

  apiKey: "AIzaSyCOEH-9CQq5V8BdD9vfQ2N5VTdXLis8Pic",

  authDomain: "h-01-4b27a.firebaseapp.com",

  projectId: "h-01-4b27a",

  storageBucket: "h-01-4b27a.appspot.com",

  messagingSenderId: "758772346839",

  appId: "1:758772346839:web:af8da9fad3499d44baa592",

  measurementId: "G-4708L73C63"

};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {

  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {

    if (permission === "granted") {

      console.log("Notification User Permission Granted."); 
      return getToken(messaging, { vapidKey: `BIxqIipiu85VOu0cimMn_uDiwkQkhvXeRvn7cQ7aya3EAWj1vHrgoyxw1I1WhChJAhhYhC0emTVF3XJ2_y_nZEM` })
        .then((currentToken) => {

          if (currentToken) {

            console.log('Client Token: ', currentToken);
            
            if (localStorage.getItem('token')) {
              const oldFcmToken = localStorage.getItem('fcmToken');

              if (oldFcmToken !== currentToken) {
                const formData = new FormData();
                formData.append('fcmToken', currentToken);

                axios.post('/fcm/set', formData)
                .then((response) => {
                  if (response.data.status === 'Success') {
                    localStorage.setItem('fcmToken', currentToken);
                  }
                });

              }
            }
          } else {
            
            console.log('Failed to generate the app registration token.');
          }
        })
        .catch((err) => {

          console.log('An error occurred when requesting to receive the token.', err);
        });
    } else {

      console.log("User Permission Denied.");
    }
  });

}

requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const clearToken = () => {
  if (messaging) {
    deleteToken(messaging).catch((e) => {});
  }
}